<template>
  <mdb-container>
    <mdb-line-chart
      :data="lineChartData"
      :options="lineChartOptions"
      :width="500"
      :height="250"
    ></mdb-line-chart>
  </mdb-container>
</template>
<script>
import { mdbLineChart, mdbContainer } from 'mdbvue'
export default {
  name: 'ChartPage',
  components: {
    mdbLineChart,
    mdbContainer
  },

  props: ['lineChartData'],
  data () {
    return {

      lineChartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)'
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)'
              }
            }
          ]
        }
      }
    }
  }
}
</script>
<style scoped>

</style>
