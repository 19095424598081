<template>
  <div>
    <TopNavBar
      v-bind:secondTierLoadingDone="secondTierLoadingDone"/>
    <b-container  class="col-12 p-0" >
            <b-row class="mt-4" align-h="center" >
        <b-col class="w-100">
          <b-card-header>
          </b-card-header>
          <b-card v-if="isTeamAdmin" class = "list-group p-1 mt-5">
                <h1>{{ $t('Team name:') }} {{ teamName }}</h1>
            <b-tabs pills card vertical>
              <b-tab active>
              <ul class="flex-wrap list-group list-group-horizontal">
                <li class="list-group-item flex-wrap small">
                  <h4>{{$t('Last login of team members')}}</h4>
                  <PieChart v-if="logInDataForPieChart.updated" :pieChartData="logInDataForPieChart"></PieChart>
                  <PieChartPlaceholder v-else class="fade-out" :pieChartData="logInDataForPieChart"></PieChartPlaceholder>
                </li>
                <li class="list-group-item flex-wrap">
                  <h4>{{$t('gender specific words per paragraph')}}</h4>
                  <b-tabs content-class="mt-3">
                    <b-tab :title='$t("1 year")' active>
                      <LineChart v-if="memberActionDataForLineChart.oneYear.updated" :lineChartData="memberActionDataForLineChart.oneYear"></LineChart>
                      <LineChartPlaceholder v-else class="fade-out" :lineChartData="memberActionDataForLineChart.oneYear"></LineChartPlaceholder>
                    </b-tab>
                    <b-tab :title='$t("5 years")'>
                      <LineChart v-if="memberActionDataForLineChart.fiveYears.updated" :lineChartData="memberActionDataForLineChart.fiveYears"></LineChart>
                      <LineChartPlaceholder v-else class="fade-out" :lineChartData="memberActionDataForLineChart.fiveYears"></LineChartPlaceholder>
                    </b-tab>
                  </b-tabs>
                </li>
                <li class="list-group-item flex-wrap">
                  <h4>{{$t('Most occuring genderspecific words')}}</h4>
                  <b-tabs content-class="mt-3">
                    <b-tab :title='$t("Total")' active>
                      <PieChart v-if="searchDataForPieChart.total.updated" :pieChartData="searchDataForPieChart.total"></PieChart>
                      <PieChartPlaceholder v-else class="fade-out" :pieChartData="searchDataForPieChart.total"></PieChartPlaceholder>
                    </b-tab>
                    <b-tab :title='$t("Year")'>
                      <PieChart v-if="searchDataForPieChart.year.updated" :pieChartData="searchDataForPieChart.year"></PieChart>
                      <PieChartPlaceholder v-else class="fade-out" :pieChartData="searchDataForPieChart.year"></PieChartPlaceholder>
                    </b-tab>
                    <b-tab :title='$t("Month")'>
                      <PieChart v-if="searchDataForPieChart.month.updated" :pieChartData="searchDataForPieChart.month"></PieChart>
                      <PieChartPlaceholder v-else class="fade-out" :pieChartData="searchDataForPieChart.month"></PieChartPlaceholder>
                    </b-tab>
                    <b-tab :title='$t("Week")'>
                      <PieChart v-if="searchDataForPieChart.week.updated" :pieChartData="searchDataForPieChart.week"></PieChart>
                      <PieChartPlaceholder v-else class="fade-out" :pieChartData="searchDataForPieChart.week"></PieChartPlaceholder>
                    </b-tab>
                    <b-tab :title='$t("Day")'>
                      <PieChart v-if="searchDataForPieChart.day.updated" :pieChartData="searchDataForPieChart.day"></PieChart>
                      <PieChartPlaceholder v-else class="fade-out" :pieChartData="searchDataForPieChart.day"></PieChartPlaceholder>
                    </b-tab>
                  </b-tabs>
                </li>
                <li class="list-group-item flex-wrap">

                  <h4>{{$t('Total usage stats')}}</h4>
                  <HorizontalBarChart v-if="memberActionDataForHorizontalBarChart.updated" :horizontalBarChartData="memberActionDataForHorizontalBarChart"></HorizontalBarChart>
                  <HorizontalBarChartPlaceholder v-else class="fade-out" :horizontalBarChartData="memberActionDataForHorizontalBarChart"></HorizontalBarChartPlaceholder>
                </li>
                <li class="list-group-item flex-wrap">
                  <h4>{{$t('Genderjust words most used for replacement')}}</h4>
                  <b-tabs content-class="mt-3">
                    <b-tab :title='$t("Total")' active>
                      <PieChart v-if="replacementDataForPieChart.total.updated" :pieChartData="replacementDataForPieChart.total"></PieChart>
                      <PieChartPlaceholder v-else class="fade-out" :pieChartData="replacementDataForPieChart.total"></PieChartPlaceholder>
                    </b-tab>
                    <b-tab :title='$t("Year")'>
                      <PieChart v-if="replacementDataForPieChart.year.updated" :pieChartData="replacementDataForPieChart.year"></PieChart>
                      <PieChartPlaceholder v-else class="fade-out" :pieChartData="replacementDataForPieChart.year"></PieChartPlaceholder>
                    </b-tab>
                    <b-tab :title='$t("Month")'>
                      <PieChart v-if="replacementDataForPieChart.month.updated" :pieChartData="replacementDataForPieChart.month"></PieChart>
                      <PieChartPlaceholder v-else class="fade-out" :pieChartData="replacementDataForPieChart.month"></PieChartPlaceholder>
                    </b-tab>
                    <b-tab :title='$t("Week")'>
                      <PieChart v-if="replacementDataForPieChart.week.updated" :pieChartData="replacementDataForPieChart.week"></PieChart>
                      <PieChartPlaceholder v-else class="fade-out" :pieChartData="replacementDataForPieChart.week"></PieChartPlaceholder>
                    </b-tab>
                    <b-tab :title='$t("Day")'>
                      <PieChart v-if="replacementDataForPieChart.day.updated" :pieChartData="replacementDataForPieChart.day"></PieChart>
                      <PieChartPlaceholder v-else class="fade-out" :pieChartData="replacementDataForPieChart.day"></PieChartPlaceholder>
                    </b-tab>
                  </b-tabs>
                </li>
              </ul>

                <template #title>
                <i class="fas fa-tachometer-alt mr-2"></i> <span>{{ $t('Overview') }}</span>
                </template>
                <b-card-text>
                  <template>
                    <div class="overflow-auto">
                      <b-table class="small" id="loginTable" striped hover :items="logIns" :fields="logInFields"></b-table>
                      <b-pagination
                        class="small"
                        v-model="logInPage"
                        :total-rows="logInCount"
                        :per-page="5"
                        aria-controls="loginTable"
                      ></b-pagination>
                      <h4 class="text-muted small">{{ $t('Number of logins:') }} {{ logInShown }} {{ $t('of') }} {{ logInCount }}</h4>
                    </div>
                  </template>
                </b-card-text>
              </b-tab>
              <b-tab>
                <template #title>
                <i class="far fas fa-users mr-2"></i> <span>{{ $t('Accounts') }}</span>
                </template>
                <b-card-text>
                  <template>

              <div v-if="error">
                <b-alert show variant="info">
                  {{error}}
                </b-alert>
              </div>
                    <div>
                      <div>
                       <span class="small text-muted align-middle">{{ $t('Action:') }} </span>
                        <select class="align-middle" v-model="selectedBulkAction">
                        <option disabled value="">{{ $t('---------') }}</option>
                        <option value="toggleAdmin">{{ $t('toggle admin') }}</option>
                        <option value="removeMember">{{ $t('remove member') }}</option>
                        <option value="resetPasswordAndSendCredentials">{{ $t('reset password and send credentials') }}</option>
                      </select> <b-button class="small align-middle" @click="runBulkAction">{{ $t('Go') }}</b-button> <span class="small text-muted align-middle">{{ numberOfSelectedMembers }} {{ $t('of') }} {{ numberOfMembers }} {{ $t('selected') }} </span>
                      </div>
                      <b-table class="mt-2" striped hover :items="teamMembers" :fields="teamMemberfields">
                      <template v-slot:head(selected)="">
                         <b-form-group>
                             <input class=" align-bottom" type="checkbox" v-model="allMembersSelected" @change="toggleSelectAll"/>
                         </b-form-group>
                      </template>
                      <template v-slot:cell(selected)="row">
                         <b-form-group>
                             <input type="checkbox" v-model="row.item.selected" @change="postMemberSelectionChange"/>
                         </b-form-group>
                      </template>
                        <template v-slot:cell(is_admin_action)="row">
                          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
                          <b-form-checkbox class="border-danger" v-model="row.item.is_team_admin" @change="toggleAdmin(row.item.id)" switch>
                          </b-form-checkbox>
                        </template>
                        <template v-slot:cell(removal_action)="row">
                          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
                          <b-button  variant="danger"  @click="showRemovalModal(row.item)">
                            {{ $t('remove') }}
                          </b-button>
                        </template>
                        <template v-slot:cell(reset_pw_send_mail_action)="row">
                          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
                          <b-button  variant="danger"  @click="showResetPasswordModal(row.item)">
                            {{ $t('set password and send credentials') }}
                          </b-button>
                        </template>
                      </b-table>
                    <b-button variant="success" @click="addUser()">{{ $t('add member') }}</b-button>
<!--                    <b-button @click="bulkToggleAdmin()">{{ $t('toggle admin') }}</b-button>-->
<!--                    <b-button @click="bulkRemoveMember()">{{ $t('remove accounts') }}</b-button>-->
<!--                    <b-button @click="bulkResetPasswordAndSendMail()">{{ $t('reset password and send mail') }}</b-button>-->
<!--                      {{ teamMembers }}-->
                    </div>
                  </template>
                </b-card-text>
              </b-tab>
              <b-tab>
                <template #title>
                <i class="far fas fa-credit-card mr-2"></i> <span>{{ $t('Billing') }}</span>
                </template>
                <b-card-text>Payment info will be visible here</b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
          <b-card v-if="!isTeamAdmin" >
              <b-alert show variant="secondary">
                <small class="form-text text-info" >{{ $t('All information in the team dashboard are only available to team admins.')}}</small>
                <small class="form-text" >{{ $t('If you think you are already admin, try logging out and in again.')}}</small>
                <small class="form-text text-success" >{{ $t('If you want to become Admin please contact one of your team admins.')}}</small>
              </b-alert>
          </b-card>
        </b-col>
      </b-row>

<!--removal modal-->
  <div >
      <b-modal hide-footer ref="removalModal" v-bind:title="$t('Admin action: Member removal')">
        <div class="d-block text-center">
          <h3 v-if="modalPotentialRemoval.length>1" class="pt-4">{{$t('Do you want to remove ')}} {{modalPotentialRemoval.length}} {{$t(' members from your team?')}}</h3>
          <h3 v-else-if="modalPotentialRemoval.length===1" class="pt-4">{{$t('Do you want to remove ')}} {{modalPotentialRemoval[0].username}} {{$t(' from your team?')}}</h3>
          <h3 v-else class="pt-4">{{$t('Please select a member for this bulk action')}}</h3>
        </div>
        <b-button @click="bulkRemoveMembers(modalPotentialRemoval)" class="mt-2 btn btn-outline-success btn-block">{{$t("Yes")}}</b-button>
        <b-button @click="hideRemovalModal" class="mt-2 btn btn-outline-info btn-block">{{$t("No")}}</b-button>
        <b-button block @click="hideRemovalModal" class="mt-2 btn btn-outline-danger btn-block">{{$t("Cancel")}}</b-button>
      </b-modal>
    </div>
<!--reset password modal-->
  <div>
      <b-modal hide-footer ref="resetPasswordModal" v-bind:title="$t('Admin action: Password Reset')">
        <div class="d-block text-center">
          <h3 v-if="modalResetPassword.length>1" class="pt-4">{{ $t('Do you want to reset the password of ') }}{{ modalResetPassword.length }}{{ $t(' members and send them their credentials plus a small FAQ?') }}</h3>
          <h3 v-else-if="modalResetPassword.length===1" class="pt-4">{{ $t('Do you want to reset the password of ') }}{{ modalResetPassword[0].username }}{{ $t(' and send them their credentials plus a small FAQ?') }}</h3>
          <h3 v-else class="pt-4">{{ $t('Please select a member for this bulk action')}}</h3>
          <small class="text-muted">{{$t('If an account wasn\'t created by an admin, the user get\'s the same mail but instead of the new, randomly created password, they get a forgot-password-link.')}}</small>
        </div>
        <b-button :disabled="resetDisabled" @click="bulkResetPasswordAndSendMail(modalResetPassword)" class="mt-2 btn btn-outline-success btn-block"><span v-if="!resetDisabled">{{$t("Yes")}}</span><span v-if="resetDisabled" class="spinner-border" role="status"></span></b-button>
        <b-button @click="hideResetPasswordModal" class="mt-2 btn btn-outline-info btn-block">{{$t("No")}}</b-button>
        <b-button block @click="hideResetPasswordModal" class="mt-2 btn btn-outline-danger btn-block">{{$t("Cancel")}}</b-button>
      </b-modal>
    </div>
    </b-container>
  </div>
</template>

<script>
import TopNavBar from '@/components/TopNavBar'
import HorizontalBarChart from '@/views/graphs/HorizontalBarChart'
import HorizontalBarChartPlaceholder from '@/views/graphs/HorizontalBarChartPlaceholder'
import PieChart from '@/views/graphs/PieChart'
import PieChartPlaceholder from '@/views/graphs/PieChartPlaceholder'
import LineChart from '@/views/graphs/LineChart'
import LineChartPlaceholder from '@/views/graphs/LineChartPlaceholder'
import { TeamService } from '@/services/TeamService'
import { AuthService } from '@/services/auth.service'
var Vue = require('vue')

export default {
  name: 'TeamDashboard',
  // insert the following code for vue-meta to work
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: this.description
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: this.title
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.description
        },
        {
          name: 'twitter:title',
          vmid: 'twitter:title',
          content: this.title
        },
        {
          name: 'twitter:description',
          vmid: 'twitter:description',
          content: this.description
        }
      ]
    }
  },
  data () {
    return {
      secondTierLoadingDone: false,
      error: '',
      shortformPlaceholder: '',
      teamMembers: [],
      teamName: '',
      numberOfMembers: 0,
      numberOfSelectedMembers: 0,
      selectedBulkAction: '',
      resetDisabled: false,
      modalResetPassword: {},
      allMembersSelected: false,
      modalPotentialRemoval: {},
      logIns: [],
      searches: [],
      replacements: [],
      logInShown: 0,
      searchShown: 0,
      replacementShown: 0,
      logInCount: 0,
      searchCount: 0,
      replacementCount: 0,
      logInPage: 1,
      searchPage: 1,
      replacementPage: 1,
      logInDataForPieChart: {
        updated: false,
        labels: [this.$t('today'), this.$t('this week'), this.$t('this month'), this.$t('this year'), this.$t('never')],
        datasets: [
          {
            data: [10, 5, 4, 2, 8],
            backgroundColor: [
              'rgba(254,203,62)',
              'rgba(252,131,112)',
              'rgba(194,84,157)',
              'rgba(126,84,158)',
              'rgba(97,103,116)'
            ],
            hoverBackgroundColor: [
              'rgba(254,203,62,0.8)',
              'rgba(252,131,112,0.8)',
              'rgba(194,84,157,0.8)',
              'rgba(126,84,158,0.8)',
              'rgba(97,103,116,0.8)'
            ]
          }
        ]
      },
      searchDataForPieChart: {
        day: {
          updated: false,
          labels: ['Nutzer', 'Kunde', 'Arzt', 'Mitarbeiter', 'sonstige'],
          datasets: [
            {
              data: [5, 4, 3, 2, 4],
              backgroundColor: [
                'rgba(254,203,62)',
                'rgba(252,131,112)',
                'rgba(194,84,157)',
                'rgba(126,84,158)',
                'rgba(97,103,116)'
              ],
              hoverBackgroundColor: [
                'rgba(254,203,62,0.8)',
                'rgba(252,131,112,0.8)',
                'rgba(194,84,157,0.8)',
                'rgba(126,84,158,0.8)',
                'rgba(97,103,116,0.8)'
              ]
            }
          ]
        },
        week: {
          updated: false,
          labels: ['Nutzer', 'Kunde', 'Arzt', 'Mitarbeiter', 'sonstige'],
          datasets: [
            {
              data: [50, 40, 30, 20, 40],
              backgroundColor: [
                'rgba(254,203,62)',
                'rgba(252,131,112)',
                'rgba(194,84,157)',
                'rgba(126,84,158)',
                'rgba(97,103,116)'
              ],
              hoverBackgroundColor: [
                'rgba(254,203,62,0.8)',
                'rgba(252,131,112,0.8)',
                'rgba(194,84,157,0.8)',
                'rgba(126,84,158,0.8)',
                'rgba(97,103,116,0.8)'
              ]
            }
          ]
        },
        month: {
          updated: false,
          labels: ['Nutzer', 'Kunde', 'Arzt', 'Mitarbeiter', 'sonstige'],
          datasets: [
            {
              data: [500, 400, 300, 200, 400],
              backgroundColor: [
                'rgba(254,203,62)',
                'rgba(252,131,112)',
                'rgba(194,84,157)',
                'rgba(126,84,158)',
                'rgba(97,103,116)'
              ],
              hoverBackgroundColor: [
                'rgba(254,203,62,0.8)',
                'rgba(252,131,112,0.8)',
                'rgba(194,84,157,0.8)',
                'rgba(126,84,158,0.8)',
                'rgba(97,103,116,0.8)'
              ]
            }
          ]
        },
        year: {
          updated: false,
          labels: ['Nutzer', 'Kunde', 'Arzt', 'Mitarbeiter', 'sonstige'],
          datasets: [
            {
              data: [5000, 4000, 3000, 2000, 4000],
              backgroundColor: [
                'rgba(254,203,62)',
                'rgba(252,131,112)',
                'rgba(194,84,157)',
                'rgba(126,84,158)',
                'rgba(97,103,116)'
              ],
              hoverBackgroundColor: [
                'rgba(254,203,62,0.8)',
                'rgba(252,131,112,0.8)',
                'rgba(194,84,157,0.8)',
                'rgba(126,84,158,0.8)',
                'rgba(97,103,116,0.8)'
              ]
            }
          ]
        },
        total: {
          updated: false,
          labels: ['Nutzer', 'Kunde', 'Arzt', 'Mitarbeiter', 'sonstige'],
          datasets: [
            {
              data: [50000, 40000, 30000, 20000, 40000],
              backgroundColor: [
                'rgba(254,203,62)',
                'rgba(252,131,112)',
                'rgba(194,84,157)',
                'rgba(126,84,158)',
                'rgba(97,103,116)'
              ],
              hoverBackgroundColor: [
                'rgba(254,203,62,0.8)',
                'rgba(252,131,112,0.8)',
                'rgba(194,84,157,0.8)',
                'rgba(126,84,158,0.8)',
                'rgba(97,103,116,0.8)'
              ]
            }
          ]
        }
      },
      replacementDataForPieChart: {
        day: {
          updated: false,
          labels: ['Nutzende', 'Kundschaft', 'ärztliches Fachpersonal', 'Mitarbeitende', 'sonstige'],
          datasets:
      [
        {
          data: [5, 4, 3, 2, 4],
          backgroundColor: [
            'rgba(254,203,62)',
            'rgba(252,131,112)',
            'rgba(194,84,157)',
            'rgba(126,84,158)',
            'rgba(97,103,116)'
          ],
          hoverBackgroundColor: [
            'rgba(254,203,62,0.8)',
            'rgba(252,131,112,0.8)',
            'rgba(194,84,157,0.8)',
            'rgba(126,84,158,0.8)',
            'rgba(97,103,116,0.8)'
          ]
        }
      ]
        },
        week: {
          updated: false,
          labels: ['Nutzende', 'Kundschaft', 'ärztliches Fachpersonal', 'Mitarbeitende', 'sonstige'],
          datasets:
      [
        {
          data: [10, 8, 6, 4, 8],
          backgroundColor: [
            'rgba(254,203,62)',
            'rgba(252,131,112)',
            'rgba(194,84,157)',
            'rgba(126,84,158,)',
            'rgba(97,103,116)'
          ],
          hoverBackgroundColor: [
            'rgba(254,203,62,0.8)',
            'rgba(252,131,112,0.8)',
            'rgba(194,84,157,0.8)',
            'rgba(126,84,158,0.8)',
            'rgba(97,103,116,0.8)'
          ]
        }
      ]
        },
        month: {
          updated: false,
          labels: ['Nutzende', 'Kundschaft', 'ärztliches Fachpersonal', 'Mitarbeitende', 'sonstige'],
          datasets:
      [
        {
          data: [20, 16, 12, 8, 16],
          backgroundColor: [
            'rgba(254,203,62)',
            'rgba(252,131,112)',
            'rgba(194,84,157)',
            'rgba(126,84,158)',
            'rgba(97,103,116)'
          ],
          hoverBackgroundColor: [
            'rgba(254,203,62,0.8)',
            'rgba(252,131,112,0.8)',
            'rgba(194,84,157,0.8)',
            'rgba(126,84,158,0.8)',
            'rgba(97,103,116,0.8)'
          ]
        }
      ]
        },
        year: {
          updated: false,
          labels: ['Nutzende', 'Kundschaft', 'ärztliches Fachpersonal', 'Mitarbeitende', 'sonstige'],
          datasets:
      [
        {
          data: [40, 32, 24, 16, 32],
          backgroundColor: [
            'rgba(254,203,62)',
            'rgba(252,131,112)',
            'rgba(194,84,157)',
            'rgba(126,84,158)',
            'rgba(97,103,116)'
          ],
          hoverBackgroundColor: [
            'rgba(254,203,62,0.8)',
            'rgba(252,131,112,0.8)',
            'rgba(194,84,157,0.8)',
            'rgba(126,84,158,0.8)',
            'rgba(97,103,116,0.8)'
          ]
        }
      ]
        },
        total: {
          updated: false,
          labels: ['Nutzende', 'Kundschaft', 'ärztliches Fachpersonal', 'Mitarbeitende', 'sonstige'],
          datasets:
      [
        {
          data: [80, 64, 48, 32, 64],
          backgroundColor: [
            'rgba(254,203,62)',
            'rgba(252,131,112)',
            'rgba(194,84,157)',
            'rgba(126,84,158)',
            'rgba(97,103,116)'
          ],
          hoverBackgroundColor: [
            'rgba(254,203,62,0.8)',
            'rgba(252,131,112,0.8)',
            'rgba(194,84,157,0.8)',
            'rgba(126,84,158,0.8)',
            'rgba(97,103,116,0.8)'
          ]
        }
      ]
        }
      },
      memberActionDataForLineChart:
        {
          oneYear: {
            updated: false,
            labels: [
              'January',
              'Februar',
              'März',
              'April',
              'Mai',
              'Juni',
              'Juli',
              'August',
              'September',
              'Oktober',
              'November',
              'Dezember'
            ],
            datasets: [
              {
                label: this.$t('Search'),
                backgroundColor: 'rgba(254,203,62,0.4)',
                borderColor: 'rgba(254,203,62)',
                borderWidth: 0.7,
                data: [0.4, 0.3, 0.2, 0.1, 0.08, 0.07, 0.06, 0.05, 0.04, 0.03, 0.03, 0.02]
              },
              {
                label: this.$t('Replacements'),
                backgroundColor: 'rgba(252,131,112,0.4)',
                borderColor: 'rgba(252,131,112)',
                borderWidth: 0.8,
                data: [0.2, 0.1, 0.02, 0.01, 0.008, 0.007, 0.006, 0.005, 0.004, 0.003, 0.003, 0.002]
              }
            ]
          },
          fiveYears: {
            updated: false,
            labels: ['1969', '1970', '1971', '1972', '1973'],
            datasets: [
              {
                label: this.$t('Search'),
                backgroundColor: 'rgba(254,203,62,0.4)',
                borderColor: 'rgba(254,203,62)',
                borderWidth: 0.7,
                data: [0.4, 0.3, 0.2, 0.1, 0.08]
              },
              {
                label: this.$t('Replacements'),
                backgroundColor: 'rgba(252,131,112,0.4)',
                borderColor: 'rgba(252,131,112)',
                borderWidth: 0.8,
                data: [0.2, 0.1, 0.02, 0.01, 0.008]
              }
            ]
          }
        },
      memberActionDataForHorizontalBarChart: {
        updated: false,
        labels: [this.$t('today'), this.$t('this week'), this.$t('this month'), this.$t('this year'), this.$t('overall')],
        datasets: [
          {
            label: this.$t('number of Logins'),
            data: [10, 98, 963, 8260, 9293],
            fill: false,
            backgroundColor: [
              'rgb(254, 203, 62,1)',
              'rgba(254, 203, 62,0.8)',
              'rgba(254, 203, 62,0.6)',
              'rgba(254, 203, 62,0.4)',
              'rgba(254, 203, 62,0.2)'
            ],
            borderColor: [
              'rgb(254, 203, 62)',
              'rgb(254, 203, 62)',
              'rgb(254, 203, 62)',
              'rgb(254, 203, 62)',
              'rgb(254, 203, 62)'
            ],
            borderWidth: 1
          },
          {
            label: this.$t('number of searches'),
            data: [90, 1040, 7490, 17390, 39022],
            fill: false,
            backgroundColor: [
              'rgba(251, 131, 116,1)',
              'rgba(251, 131, 116,0.8)',
              'rgba(251, 131, 116,0.6)',
              'rgba(251, 131, 116,0.4)',
              'rgba(251, 131, 116,0.2)'
            ],
            borderColor: [
              'rgba(251, 131, 116)',
              'rgba(251, 131, 116)',
              'rgba(251, 131, 116)',
              'rgba(251, 131, 116)',
              'rgba(251, 131, 116)'
            ],
            borderWidth: 1
          },
          {
            label: this.$t('number of replacements'),
            data: [9, 104, 749, 7690, 17002],
            fill: false,
            backgroundColor: [
              'rgba(194, 84, 157,1)',
              'rgba(194, 84, 157,0.8)',
              'rgba(194, 84, 157,0.6)',
              'rgba(194, 84, 157,0.4)',
              'rgba(194, 84, 157,0.2)'
            ],
            borderColor: [
              'rgba(194, 84, 157)',
              'rgba(194, 84, 157)',
              'rgba(194, 84, 157)',
              'rgba(194, 84, 157)',
              'rgba(194, 84, 157)'
            ],
            borderWidth: 1
          },
          {
            label: this.$t('number of analysed paragraphs'),
            data: [960, 14040, 75490, 72390, 104022],
            fill: false,
            backgroundColor: [
              'rgba(126, 84, 158,1)',
              'rgba(126, 84, 158,0.8)',
              'rgba(126, 84, 158,0.6)',
              'rgba(126, 84, 158,0.4)',
              'rgba(126, 84, 158,0.2)'
            ],
            borderColor: [
              'rgba(126, 84, 158)',
              'rgba(126, 84, 158)',
              'rgba(126, 84, 158)',
              'rgba(126, 84, 158)',
              'rgba(126, 84, 158)'
            ],
            borderWidth: 1
          }
        ]
      }
    }
  },
  mounted () {
    this.fetchTeamMembers()
    this.fetchTeamAddInStats()
  },
  watch: {
    logInPage: function () {
      this.fetchAndSetTeamLoginTablePage()
    },
    searchPage: function () {
      this.fetchAndSetTeamSearchTablePage()
    },
    replacementPage: function () {
      this.fetchAndSetTeamReplacementTablePage()
    }
  },
  computed: {
    title: function () {
      return 'Team-Dashboard · gender app'
    },
    description: function () {
      return this.$t('all information concerning your team')
    },
    isTeamAdmin: function () {
      const isAdminUser = Vue.default.cookie.get('is_team_admin')
      const isAdminUserLowercase = isAdminUser ? isAdminUser.toLowerCase() : 'false'
      return isAdminUserLowercase === 'true'
    },
    teamMemberfields () {
      return [
        { key: 'selected', label: this.$t('selected'), sortable: false },
        { key: 'username', label: this.$t('username'), sortable: true },
        { key: 'email', label: this.$t('email'), sortable: true },
        { key: 'is_admin_action', label: this.$t('is team admin'), sortable: true }
        // { key: 'removal_action', label: this.$t('remove user'), sortable: true, variant: 'danger' },
        // { key: 'reset_pw_send_mail_action', label: this.$t('reset password and send credentials'), sortable: true, variant: 'danger' }
      ]
    },
    logInFields () {
      return [
        { key: 'username', label: this.$t('user'), sortable: true },
        { key: 'created', label: this.$t('date of login'), sortable: true }
      ]
    },
    searchFields () {
      return [
        { key: 'username', label: this.$t('user'), sortable: true },
        { key: 'created', label: this.$t('date of search'), sortable: true },
        { key: 'searchTerm', label: this.$t('search term'), sortable: true }
      ]
    },
    replacementFields () {
      return [
        { key: 'username', label: this.$t('user'), sortable: true },
        { key: 'created', label: this.$t('date of replacement'), sortable: true },
        { key: 'searchTerm', label: this.$t('search term'), sortable: true },
        { key: 'replacement', label: this.$t('replacement'), sortable: true }
      ]
    }
  },
  methods: {
    toggleSelectAll () {
      if (this.allMembersSelected) { this.selectAll() } else { this.selectNone() }
      this.postMemberSelectionChange()
    },
    postMemberSelectionChange () {
      const reducer = (accumulator, currentValue) => (currentValue.selected ? 1 : 0) + accumulator
      this.numberOfSelectedMembers = this.teamMembers.reduce(reducer, 0)
      this.allMembersSelected = this.numberOfSelectedMembers === this.numberOfMembers
    },
    showResetPasswordModal (potentialPasswordResetData) {
      this.modalResetPassword = potentialPasswordResetData
      this.$refs.resetPasswordModal.show()
    },
    hideResetPasswordModal () {
      this.modalResetPassword = {}
      this.$refs.resetPasswordModal.hide()
    },
    showRemovalModal (potentialRemovalMemberData) {
      this.modalPotentialRemoval = potentialRemovalMemberData
      this.$refs.removalModal.show()
    },
    hideRemovalModal () {
      this.modalPotentialRemoval = {}
      this.$refs.removalModal.hide()
    },
    addUser () {
      this.$router.push('/add-team-member/')
    },
    selectAll () {
      const allMembers = this.teamMembers
      allMembers.forEach((member, index) => {
        allMembers.splice(index, 1, Object.assign({}, member, { selected: true }))
      })
    },
    selectNone () {
      const allMembers = this.teamMembers
      allMembers.forEach((member, index) => {
        allMembers.splice(index, 1, Object.assign({}, member, { selected: false }))
      })
    },
    async runBulkAction () {
      this.error = ''
      if (this.numberOfSelectedMembers === 0) {
        this.error = this.$t('select members')
        return
      }
      const selectedMembers = this.teamMembers.filter(o => o.selected)
      if (this.selectedBulkAction === 'toggleAdmin') {
        await this.bulkToggleAdmin(selectedMembers)
      } else if (this.selectedBulkAction === 'removeMember') {
        await this.showRemovalModal(selectedMembers)
      } else if (this.selectedBulkAction === 'resetPasswordAndSendCredentials') {
        await this.showResetPasswordModal(selectedMembers)
      } else {
        this.error = this.$t('Please choose a bulk action')
        return
      }
      await this.fetchTeamMembers()
      await this.fetchTeamAddInStats()
    },
    async bulkToggleAdmin (selectedMembers) {
      for (const member of selectedMembers) {
        await this.toggleAdmin(member.id)
      }
      return Promise.resolve()
    },
    async bulkRemoveMembers (selectedMembers) {
      for (const member of selectedMembers) {
        await this.removeAccount(member.id)
      }
      return Promise.resolve()
    },
    async bulkResetPasswordAndSendMail (selectedMembers) {
      for (const member of selectedMembers) {
        await this.resetPasswordAndSendMail(member.id)
      }
      return Promise.resolve()
    },
    toggleAdmin (userId) {
      return TeamService.toggleAdmin(userId)
        .then((response) => {
          console.log(response.data)
        }).catch((error) => {
          console.log(error)
        })
    },
    removeAccount (userId) {
      return TeamService.removeAccount(userId)
        .then((response) => {
          console.log(response.data)
          this.hideRemovalModal()
          this.fetchTeamMembers()
          this.fetchTeamAddInStats()
        }).catch((error) => {
          console.log(error)
        })
    },
    resetPasswordAndSendMail (userId) {
      this.resetDisabled = true
      return AuthService.resetPasswordAndSendMail({ userId: userId })
        .then((response) => {
          console.log(response.data)
          this.resetDisabled = false
          this.hideResetPasswordModal()
        }).catch((error) => {
          this.resetDisabled = false
          console.log(error)
        })
    },
    fetchAndSetTeamLoginTablePage (loginPage) {
      const page = loginPage || this.logInPage
      TeamService.fetchTeamAddInLoginTablePage(page)
        .then((response) => {
          const results = response.data.results
          const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }

          const addInUsageForView = results.map((x) => ({ username: x.user.username, created: `${new Date(x.created).toLocaleDateString('de-CH', options)} ${new Date(x.created).toLocaleTimeString('de-CH')}` }))
          this.logIns = addInUsageForView
          this.logInShown = addInUsageForView.length
          this.logInCount = response.data.count
        }).catch((error) => {
          console.log(error)
        })
    },
    fetchAndSetTeamSearchTablePage (searchPage) {
      const page = searchPage || this.searchPage
      TeamService.fetchTeamAddInSearchTablePage(page)
        .then((response) => {
          const results = response.data.results
          const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }

          const addInUsageForView = results.map((x) => ({ searchTerm: x.search_term, username: x.user.username, created: `${new Date(x.created).toLocaleDateString('de-CH', options)} ${new Date(x.created).toLocaleTimeString('de-CH')}` }))
          this.searches = addInUsageForView
          this.searchShown = addInUsageForView.length
          this.searchCount = response.data.count
        }).catch((error) => {
          console.log(error)
        })
    },
    fetchAndSetTeamReplacementTablePage (replacementPage) {
      const page = replacementPage || this.replacementPage
      TeamService.fetchTeamAddInReplacementTablePage(page)
        .then((response) => {
          // console.log(response)
          const results = response.data.results
          const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
          const addInUsageForView = results.map((x) => ({ replacement: `${x.replacement.singular_gender_neutral}${x.replacement.singular_gender_neutral && x.replacement.plural_gender_neutral ? ' / ' : ''}${x.replacement.plural_gender_neutral}`, searchTerm: x.search_term, username: x.user.username, created: `${new Date(x.created).toLocaleDateString('de-CH', options)} ${new Date(x.created).toLocaleTimeString('de-CH')}` }))
          this.replacements = addInUsageForView
          this.replacementShown = addInUsageForView.length
          this.replacementCount = response.data.count
        }).catch((error) => {
          console.log(error)
        })
    },
    fetchAndSetTeamAddInLoginStats () {
      TeamService.fetchTeamAddInLoginStats()
        .then((response) => {
          const allhorizontalBarChartDataset = this.memberActionDataForHorizontalBarChart.datasets
          if (!response.data.addinStats) return
          const barLoginDataWithOverlap = response.data.addinStats.logins.total
          if (allhorizontalBarChartDataset) allhorizontalBarChartDataset[0].data = [barLoginDataWithOverlap.day, barLoginDataWithOverlap.week, barLoginDataWithOverlap.month, barLoginDataWithOverlap.year, barLoginDataWithOverlap.total]
          const barSearchDataWithOverlap = response.data.addinStats.searches.total
          if (allhorizontalBarChartDataset) allhorizontalBarChartDataset[1].data = [barSearchDataWithOverlap.day, barSearchDataWithOverlap.week, barSearchDataWithOverlap.month, barSearchDataWithOverlap.year, barSearchDataWithOverlap.total]
          const barReplacementDataWithOverlap = response.data.addinStats.replacements.total
          if (allhorizontalBarChartDataset) allhorizontalBarChartDataset[2].data = [barReplacementDataWithOverlap.day, barReplacementDataWithOverlap.week, barReplacementDataWithOverlap.month, barReplacementDataWithOverlap.year, barReplacementDataWithOverlap.total]
          const barParagraphDataWithOverlap = response.data.addinStats.analysedParagraphs.total
          if (allhorizontalBarChartDataset) allhorizontalBarChartDataset[3].data = [barParagraphDataWithOverlap.day, barParagraphDataWithOverlap.week, barParagraphDataWithOverlap.month, barParagraphDataWithOverlap.year, barParagraphDataWithOverlap.total]

          this.memberActionDataForHorizontalBarChart.updated = true
          const allLoginPieChartDataset = this.logInDataForPieChart.datasets
          const pieLoginDataWithOverlap = response.data.addinStats.logins.distinct
          const pieLoginDataWithoutOverlapWeek = pieLoginDataWithOverlap.week - pieLoginDataWithOverlap.day
          const pieLoginDataWithoutOverlapMonth = pieLoginDataWithOverlap.month - pieLoginDataWithOverlap.week
          const pieLoginDataWithoutOverlapYear = pieLoginDataWithOverlap.year - pieLoginDataWithOverlap.month
          if (allLoginPieChartDataset) allLoginPieChartDataset[0].data = [pieLoginDataWithOverlap.day, pieLoginDataWithoutOverlapWeek, pieLoginDataWithoutOverlapMonth, pieLoginDataWithoutOverlapYear, pieLoginDataWithOverlap.never]
          this.logInDataForPieChart.updated = true

          let searchDataForPieChart = this.searchDataForPieChart.total
          let pieSearchData = response.data.addinStats.searches.top.total
          searchDataForPieChart.datasets[0].data = pieSearchData.map((e) => e.member_action_count)
          searchDataForPieChart.labels = pieSearchData.map((e) => e.search_term)
          searchDataForPieChart.updated = true

          searchDataForPieChart = this.searchDataForPieChart.year
          pieSearchData = response.data.addinStats.searches.top.year
          searchDataForPieChart.datasets[0].data = pieSearchData.map((e) => e.member_action_count)
          searchDataForPieChart.labels = pieSearchData.map((e) => e.search_term)
          searchDataForPieChart.updated = true
          searchDataForPieChart = this.searchDataForPieChart.month
          pieSearchData = response.data.addinStats.searches.top.month
          searchDataForPieChart.datasets[0].data = pieSearchData.map((e) => e.member_action_count)
          searchDataForPieChart.labels = pieSearchData.map((e) => e.search_term)
          searchDataForPieChart.updated = true
          searchDataForPieChart = this.searchDataForPieChart.week
          pieSearchData = response.data.addinStats.searches.top.week
          searchDataForPieChart.datasets[0].data = pieSearchData.map((e) => e.member_action_count)
          searchDataForPieChart.labels = pieSearchData.map((e) => e.search_term)
          searchDataForPieChart.updated = true
          searchDataForPieChart = this.searchDataForPieChart.day
          pieSearchData = response.data.addinStats.searches.top.day
          searchDataForPieChart.datasets[0].data = pieSearchData.map((e) => e.member_action_count)
          searchDataForPieChart.labels = pieSearchData.map((e) => e.search_term)
          searchDataForPieChart.updated = true

          let replacementDataForPieChart = this.replacementDataForPieChart.total
          let pieReplacementData = response.data.addinStats.replacements.top.total
          replacementDataForPieChart.datasets[0].data = pieReplacementData.map((e) => e.member_action_count)
          replacementDataForPieChart.labels = pieReplacementData.map((e) => e.replacement__plural_gender_neutral)
          replacementDataForPieChart.updated = true

          replacementDataForPieChart = this.replacementDataForPieChart.year
          pieReplacementData = response.data.addinStats.replacements.top.year
          replacementDataForPieChart.datasets[0].data = pieReplacementData.map((e) => e.member_action_count)
          replacementDataForPieChart.labels = pieReplacementData.map((e) => e.replacement__plural_gender_neutral)
          replacementDataForPieChart.updated = true

          replacementDataForPieChart = this.replacementDataForPieChart.month
          pieReplacementData = response.data.addinStats.replacements.top.month
          replacementDataForPieChart.datasets[0].data = pieReplacementData.map((e) => e.member_action_count)
          replacementDataForPieChart.labels = pieReplacementData.map((e) => e.replacement__plural_gender_neutral)
          replacementDataForPieChart.updated = true

          replacementDataForPieChart = this.replacementDataForPieChart.week
          pieReplacementData = response.data.addinStats.replacements.top.week
          replacementDataForPieChart.datasets[0].data = pieReplacementData.map((e) => e.member_action_count)
          replacementDataForPieChart.labels = pieReplacementData.map((e) => e.replacement__plural_gender_neutral)
          replacementDataForPieChart.updated = true

          replacementDataForPieChart = this.replacementDataForPieChart.day
          pieReplacementData = response.data.addinStats.replacements.top.day
          replacementDataForPieChart.datasets[0].data = pieReplacementData.map((e) => e.member_action_count)
          replacementDataForPieChart.labels = pieReplacementData.map((e) => e.replacement__plural_gender_neutral)
          replacementDataForPieChart.updated = true

          const lineChartDataOneYear = this.memberActionDataForLineChart.oneYear
          const lineRatioSearchDataOneYear = response.data.addinStats.searches.ratio.oneYear
          const lineRatioReplacementDataOneYear = response.data.addinStats.replacements.ratio.oneYear
          lineChartDataOneYear.labels = lineRatioSearchDataOneYear.map((e) => e.dateEnd)
          lineChartDataOneYear.datasets[0].label = this.$t('Searches')
          lineChartDataOneYear.datasets[1].label = this.$t('Replacements')
          lineChartDataOneYear.datasets[0].data = lineRatioSearchDataOneYear.map((e) => e.ratio)
          lineChartDataOneYear.datasets[1].data = lineRatioReplacementDataOneYear.map((e) => e.ratio)
          lineChartDataOneYear.updated = true

          const lineChartDataFiveYears = this.memberActionDataForLineChart.fiveYears
          const lineRatioSearchDataFiveYears = response.data.addinStats.searches.ratio.fiveYears
          const lineRatioReplacementDataFiveYears = response.data.addinStats.replacements.ratio.fiveYears
          lineChartDataFiveYears.labels = lineRatioSearchDataFiveYears.map((e) => e.dateEnd)
          lineChartDataFiveYears.datasets[0].label = this.$t('Searches')
          lineChartDataFiveYears.datasets[1].label = this.$t('Replacements')
          lineChartDataFiveYears.datasets[0].data = lineRatioSearchDataFiveYears.map((e) => e.ratio)
          lineChartDataFiveYears.datasets[1].data = lineRatioReplacementDataFiveYears.map((e) => e.ratio)
          lineChartDataFiveYears.updated = true
        }).catch((error) => {
          console.log(error)
        })
    },
    fetchTeamAddInStats () {
      this.fetchAndSetTeamLoginTablePage()
      // this.fetchAndSetTeamSearchTablePage()
      // this.fetchAndSetTeamReplacementTablePage()
      this.fetchAndSetTeamAddInLoginStats()
    },
    fetchTeamMembers () {
      return TeamService.getTeamMembers()
        .then((response) => {
          const results = response.data.results
          if (results.length === 1) {
            const myTeam = results[0]
            this.teamName = myTeam.name
            if (myTeam.team_members.length) {
              this.teamMembers = myTeam.team_members
              this.numberOfMembers = myTeam.team_members.length
              this.allMembersSelected = false
              this.numberOfSelectedMembers = 0
            }
          }
        }).catch((error) => {
          console.log(error)
        })
    },
    getUsername () {
      return Vue.default.cookie.get('username', 'Profil')
    }
  },
  components: { TopNavBar, HorizontalBarChart, HorizontalBarChartPlaceholder, PieChart, PieChartPlaceholder, LineChart, LineChartPlaceholder }
}
</script>

<style scoped>
.sitenav-menu{
  text-decoration: none;
  color: #222222;
}
.sitenav-menu:active{
  text-decoration: none;
  color: white !important;
}

  .fade-out {
    animation: Pulsate 4s linear infinite;
  }

  @keyframes Pulsate {
    from {
      opacity: 0.4;
    }
    50% {
      opacity: 0.1;
    }
    to {
      opacity: 0.4;
    }
  }
</style>
