<template>
  <mdb-container>
    <mdb-pie-chart
      datalabels
      :data="pieChartData"
      :options="pieChartOptions"
      :width="500"
      :height="320"
    />
  </mdb-container>
</template>

<script> import { mdbPieChart, mdbContainer } from 'mdbvue'
export default {
  name: 'ChartPage',
  components: {
    mdbPieChart,
    mdbContainer
  },
  props: ['pieChartData'],
  data () {
    return {
      pieChartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: 'white',
            align: 'center',
            font: {
              size: 16
            },
            formatter: value => {
              const [dataset] = this.pieChartData.datasets
              const setValue = dataset.data.reduce((a, b) => a + b)

              return `${Math.round((value / setValue) * 100)}%`
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
