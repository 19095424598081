<template>
  <mdb-container>
    <mdb-horizontal-bar-chart
      :data="horizontalBarChartData"
      :options="horizontalBarChartOptions"
      :width="500"
      :height="320"
    ></mdb-horizontal-bar-chart>
  </mdb-container>
</template>

<script>
import { mdbHorizontalBarChart, mdbContainer } from 'mdbvue'
export default {
  name: 'HorizontalBarChart',
  components: {
    mdbHorizontalBarChart,
    mdbContainer
  },
  props: ['horizontalBarChartData'],

  data () {
    return {
      horizontalBarChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)'
              }
            }
          ]
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
